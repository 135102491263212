const DURATION_IN_DAYS = 30;
const DIRECT_ACCESS = -1;
const STORE_KEY = "businessProvider";
const QS_NAMES = ["hote", "hoteId", "hoteID", "hote_id", "hoteid"];

const readParam = (name) => {
  const pairs = document.location.search.substring(1).split("&");
  const xs = pairs
    .filter((p) => p.indexOf(`${name}=`) === 0)
    .map((p) => decodeURIComponent(p.split("=")[1]));
  return xs.length === 0 ? undefined : xs[xs.length - 1];
};

const getUrlHote = () => {
  return QS_NAMES.reduce((acc, name) => acc || readParam(name), undefined);
};

const readRemanentFromStorage = () => {
  const stored = localStorage.getItem(STORE_KEY);
  if (stored) return JSON.parse(stored);
};

const setRemanentProvider = (remanent) => {
  const now = new Date();
  now.setDate(now.getDate() + DURATION_IN_DAYS);
  const exp = now.getTime();
  const remanentId = Number(remanent);
  const id = isNaN(remanentId) ? DIRECT_ACCESS : remanentId;
  localStorage.setItem(STORE_KEY, JSON.stringify({ exp, id }));
  return id;
};

const getRemanentProvider = () => {
  const provider = readRemanentFromStorage();
  if (provider) {
    const now = new Date();
    const { exp, id } = provider;
    if (now.getTime() > exp) {
      localStorage.removeItem(STORE_KEY);
      return;
    }
    return id;
  }
};

const setCurrentProvider = (id) => {
  if (id !== undefined && id !== DIRECT_ACCESS) {
    sessionStorage.setItem(STORE_KEY, JSON.stringify(id));
  }
};

let remanent = getRemanentProvider();
if (remanent === undefined) {
  const param = getUrlHote() || DIRECT_ACCESS;
  remanent = setRemanentProvider(param);
}

setCurrentProvider(remanent);
